import Link from "next/link";
import { useRouter } from "next/router";
import ReactCountryFlag from "react-country-flag";

const LanguageFlags: React.FC = () => {
  const router = useRouter();

  const currentLanguage = router.locale?.toUpperCase();

  const showPlFlag = currentLanguage === "EN";
  const showUsFlag = currentLanguage === "PL";

  return (
    <div className="fixed right-4 bottom-3 z-[999]">
      {showPlFlag && (
        <Link href={router.pathname} locale="pl" passHref>
          <button aria-label="Change language to Polish" >
            <ReactCountryFlag countryCode="PL" style={{ fontSize: "2em" }} />
          </button>
        </Link>
      )}
      {showUsFlag && (
        <Link href={router.pathname} locale="en" passHref>
          <button aria-label="Change language to English" >
            <ReactCountryFlag countryCode="GB" style={{ fontSize: "2em" }} />
          </button>
        </Link>
      )}
    </div>
  );
};

export default LanguageFlags;
