import { useEffect } from "react";
import { event } from "nextjs-google-analytics";

const useAutoAnchorTracking = (): void => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const anchor = (e.target as HTMLElement).closest("a"); // Get the closest anchor tag
      if (anchor) {
        event("anchor-click-" + (anchor as HTMLAnchorElement).href, {
          category: "Links",
          action: "click",
          label: (anchor as HTMLAnchorElement).href || "unknown", // Use the href attribute or a default value
        });
      }
    };

    // Add event listeners for the whole document
    document.addEventListener("click", handleClick);

    // Cleanup the event listeners on unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
};

export { useAutoAnchorTracking };
