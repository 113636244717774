export const appConfig = {
  apiBearerToken: process.env.NEXT_PUBLIC_API_KEY,

  assetsUrl: process.env.NEXT_PUBLIC_API_HOST || "localhost",

  serverApiUrl: process.env.SERVER_API_HOST
    ? process.env.SERVER_API_HOST + "/api"
    : "http://127.0.0.1:1337/api",
  clientApiUrl: process.env.NEXT_PUBLIC_API_HOST
    ? process.env.NEXT_PUBLIC_API_HOST + "/api"
    : "http://127.0.0.1:1337/api",
};
