import { useState, useEffect } from "react";
import useSWR from "swr";
import { appConfig } from "../appConfig";
import { useMainDict } from "./useTranslation";
import { fetcher } from "../fetcher";
import { BlocksContent } from "@strapi/blocks-react-renderer";

export type BannerType = {
  id: number;
  attributes: {
    text: BlocksContent;
    bgColor: string;
    closeable: boolean;
    tablerIcon: string;
    linksTarget: string;
    timeoutMs: number;
    dismissOnLinkClick: boolean;
  };
};

type BannersResponseType = {
  data: BannerType[];
  meta: {};
};

const getApiRoute = (language: string) => `/banners?locale=${language}`;

export async function preFetchBanners(language: string) {
  const apiRoute = getApiRoute(language);
  const data = await fetcher<BannersResponseType>(apiRoute);
  return { [apiRoute]: data };
}

export function useBanner() {
  const { language } = useMainDict();
  const [dismissedBanners, setDismissedBanners] = useState<number[]>([]);

  useEffect(() => {
    const storedDismissedBanners = localStorage.getItem("dismissedBanners");
    if (storedDismissedBanners) {
      setDismissedBanners(JSON.parse(storedDismissedBanners));
    }
  }, []);

  const { data, error } = useSWR<BannersResponseType>(
    getApiRoute(language),
    fetcher
  );

  const banners =
    data?.data.filter((banner) => !dismissedBanners.includes(banner.id)) || [];

  const dismiss = (id: number) => {
    const updatedDismissedBanners = [...dismissedBanners, id];
    setDismissedBanners(updatedDismissedBanners);
    localStorage.setItem(
      "dismissedBanners",
      JSON.stringify(updatedDismissedBanners)
    );
  };

  return {
    banners,
    dismiss,
    isLoading: !error && !data,
    isError: error,
  };
}
