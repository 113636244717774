import { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";

export const useMainDict = () => {
  const { t, lang } = useTranslation("main");

  return useMemo(() => {
    return { t, language: lang };
  }, [lang, t]);
};
