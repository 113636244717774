import React from "react";
import { SWRConfig } from "swr";
import { fetcher } from "./fetcher";

const SWRProvider: React.FC<{ children: React.ReactNode; fallback?: any }> = ({
  children,
  fallback = {},
}) => {
  return (
    <SWRConfig
      value={{
        fetcher,
        fallback,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SWRProvider;
