import { event } from "nextjs-google-analytics";

export const EventCategory = {
  LINKS: "Links",
  USER_INTERACTION: "User Interaction",
};

export const EventAction = {
  CLICK: "click",
  HOVER: "hover",
  BECOMES_VISIBLE: "visible",
};

export const EventLabel = {
  OUR_STORY: "Our Story",
  INGREDIENTS: "Ingredients",
  BANNER: "Banner",
  Menu: "Menu",
};

const useGAnalytics = () => {
  const trackEvent = (
    eventName: string,
    category = EventCategory.LINKS,
    action = EventAction.CLICK,
    label = EventLabel.OUR_STORY
  ) => {
    event(eventName, {
      category: category,
      action: action,
      label: label,
    });
  };

  return { trackEvent };
};

export default useGAnalytics;
