import React, { useState, useEffect, ReactElement } from "react";
import { BannerType, useBanner } from "@/libs/hooks/useBanner";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { IconAlertTriangle, IconInfoCircle, IconX } from "@tabler/icons-react";
import { Banner } from "flowbite-react";
import Link from "next/link";
import { cloneDeep } from "lodash";
import useGAnalytics, {
  EventCategory,
} from "@/libs/hooks/tracking/useGAnalytics";

type IconNames = "info-circle" | "alert-triangle";

export const BannerComponent: React.FC = () => {
  const { banners, dismiss } = useBanner();
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [onTheWayOut, setOnTheWayOut] = useState(false);

  const [currentBanner, setCurrentBanner] = useState<BannerType>();
  const [nextBanner, setNextBanner] = useState<BannerType>();

  const { trackEvent } = useGAnalytics();

  const setBanners = () => {
    setCurrentBanner(cloneDeep(banners[0]));
    setNextBanner(cloneDeep(banners[1]));

    setTimeout(() => {
      setOnTheWayOut(false);
    }, 500);
  };

  useEffect(() => {
    if (currentBanner && nextBanner && onTheWayOut) {
      setTimeout(() => {
        setBanners();
      }, nextBanner.attributes.timeoutMs || 2000);
    } else if (!currentBanner) {
      setBanners();
    } else if (banners.length === 0) {
      setBanners();
    }
  }, [banners]);

  useEffect(() => {
    if (currentBanner) {
      const timer = setTimeout(
        () => setIsBannerVisible(true),
        currentBanner?.attributes?.timeoutMs || 2500
      );
      return () => clearTimeout(timer);
    }
  }, [currentBanner]);

  const handleDismiss = (bannerId: number) => {
    setOnTheWayOut(true);
    trackEvent(
      "banner-dismissed-clicked",
      EventCategory.USER_INTERACTION,
      "banner-dismissed",
      bannerId.toString()
    );
    setTimeout(() => {
      dismiss(bannerId);
    }, 500);
  };

  const textColorClassName = (bgColor: string) => {
    console.log(bgColor);
    switch (bgColor) {
      case "pizza-yellow":
        return "text-pizza-gray";
      case null:
        return "text-pizza-gray";
      default:
        return "text-pizza-papaya";
    }
  };

  const renderIcon = (iconName: IconNames): ReactElement | null => {
    const icons: { [key in IconNames]: ReactElement } = {
      "info-circle": <IconInfoCircle className="mr-2 text-lg min-w-6" />,
      "alert-triangle": <IconAlertTriangle className="mr-2 text-lg min-w-6" />,
    };
    return icons[iconName] || null;
  };

  return (
    <>
      {isBannerVisible && currentBanner && (
        <Banner
          key={currentBanner.id}
          className={`fixed top-0 w-full z-[300] font-futura 
          ${onTheWayOut ? "banner-slide-up" : "banner-slide-down"}`}
        >
          <div
            className={`flex w-full justify-between drop-shadow-lg bg-${currentBanner.attributes.bgColor} bg-gray-50 p-4`}
          >
            <div className="mx-auto flex items-center">
              <p
                className={`flex items-center text-sm font-normal ${textColorClassName(
                  currentBanner.attributes.bgColor
                )}`}
              >
                {renderIcon(currentBanner.attributes.tablerIcon as IconNames)}
                <span className="[&_p]:inline text-lg ">
                  <BlocksRenderer
                    blocks={{
                      link: ({ url, plainText, children }) => (
                        <Link
                          href={url}
                          onClick={() =>
                            currentBanner.attributes.dismissOnLinkClick &&
                            handleDismiss(currentBanner.id)
                          }
                          target={currentBanner.attributes.linksTarget}
                          className="text-blue-700 underline"
                        >
                          {children}
                        </Link>
                      ),
                    }}
                    content={currentBanner.attributes.text}
                  />
                </span>
              </p>
            </div>
            <Banner.CollapseButton
              color="transparent"
              className={`border-0 bg-transparent ${textColorClassName(
                currentBanner.attributes.bgColor
              )}`}
              onClick={() => handleDismiss(currentBanner.id)}
            >
              <IconX />
            </Banner.CollapseButton>
          </div>
        </Banner>
      )}
    </>
  );
};
