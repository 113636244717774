
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "@/styles/globals.css";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import "flowbite";
import SWRProvider from "@/libs/swrProvider";
import { GoogleAnalytics, event } from "nextjs-google-analytics";
import { useAutoAnchorTracking } from "@/libs/hooks/tracking/useAutoAnchorTracking";
import LanguageFlags from "@/+components/LanguageFlags";
import { BannerComponent } from "@/+components/Banner";
import CookieConsent from "react-cookie-consent";
import { useMainDict } from "@/libs/hooks/useTranslation";
import { NextUIProvider } from "@nextui-org/react";

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

const __Page_Next_Translate__ = function App({ Component, pageProps }: AppProps) {
  useAutoAnchorTracking();
  const { t } = useMainDict();

  return (
    <>
      <Head>
        <title>Pizzicletta - Pizza Napoletana - Podgórze Kraków</title>
        <meta
          name="title"
          content="Authentic Neapolitan Pizzeria in Kraków Podgórze | Pizzicletta - Pizza
      Napoletana"
        />
        <meta
          name="description"
          content="Odkryj najlepszą neapolitańską pizzę w Krakowie Podgórze. Przeżyj prawdziwy smak Neapolu dzięki naszym ręcznie robionym pizzom przygotowywanym według tradycyjnych receptur i z wysokiej jakości składników. Odwiedź Pizzicletta już dziś!"
        />
        <meta
          name="keywords"
          content="Neapolitan pizzeria Kraków Podgórze,Authentic pizza Kraków,Traditional Italian pizza,Best pizza in Podgórze,Pizzeria with handmade dough,Fresh ingredients pizza Kraków,Neapolitan-style pizza restaurant,pizzeria neapolitańska Kraków Podgórze,Autentyczna pizza Kraków,Tradycyjna włoska pizza,Najlepsza pizza w Podgórzu,Pizzeria z ręcznie robionym ciastem,Pizza ze świeżych składników Kraków,pizzeria w stylu neapolitańskim"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Polish" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta
          name="description"
          content="Odkryj najlepszą neapolitańską pizzę w Krakowie Podgórze. Przeżyj prawdziwy smak Neapolu dzięki naszym ręcznie robionym pizzom przygotowywanym według tradycyjnych receptur i z wysokiej jakości składników. Odwiedź Pizzicletta już dziś!"
        />

        <meta property="og:url" content="http://www.pizzicletta.net" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Autentyczna pizzeria neapolitańska w Krakowie Podgórze | Pizzicletta"
        />
        <meta
          property="og:description"
          content="Odkryj najlepszą neapolitańską pizzę w Krakowie Podgórze. Przeżyj prawdziwy smak Neapolu dzięki naszym ręcznie robionym pizzom przygotowywanym według tradycyjnych receptur i z wysokiej jakości składników. Odwiedź Pizzicletta już dziś!"
        />
        <meta
          property="og:image"
          content="https://res.opengraph.xyz/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/pizzicletta/Autentyczna%20pizzeria%20neapolita%C5%84ska%20w%20Krakowie%20Podg%C3%B3rze%20%7C%20Pizzicletta/Odkryj%20najlepsz%C4%85%20neapolita%C5%84sk%C4%85%20pizz%C4%99%20w%20Krakowie%20Podg%C3%B3rze.%20Prze%C5%BCyj%20prawdziwy%20smak%20Neapolu%20dzi%C4%99ki%20naszym%20r%C4%99cznie%20robionym%20pizzom%20przygotowywanym%20wed%C5%82ug%20tradycyjnych%20receptur%20i%20z%20wysokiej%20jako%C5%9Bci%20sk%C5%82adnik%C3%B3w.%20Odwied%C5%BA%20Pizzicletta%20ju%C5%BC%20dzi%C5%9B!/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2F731d6248-a2f2-4c89-abed-8f9a95cc84d9.jpg%3Ftoken%3DsPH3VQt-_QkVBgmm-TcHCovWmpAYuGJl7aAaLbTegLc%26height%3D624%26width%3D1200%26expires%3D33239784235/og.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="pizzicletta.net" />
        <meta property="twitter:url" content="http://www.pizzicletta.net" />
        <meta
          name="twitter:title"
          content="Autentyczna pizzeria neapolitańska w Krakowie Podgórze | Pizzicletta"
        />
        <meta
          name="twitter:description"
          content="Odkryj najlepszą neapolitańską pizzę w Krakowie Podgórze. Przeżyj prawdziwy smak Neapolu dzięki naszym ręcznie robionym pizzom przygotowywanym według tradycyjnych receptur i z wysokiej jakości składników. Odwiedź Pizzicletta już dziś!"
        />
        <meta
          name="twitter:image"
          content="https://res.opengraph.xyz/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/pizzicletta/Autentyczna%20pizzeria%20neapolita%C5%84ska%20w%20Krakowie%20Podg%C3%B3rze%20%7C%20Pizzicletta/Odkryj%20najlepsz%C4%85%20neapolita%C5%84sk%C4%85%20pizz%C4%99%20w%20Krakowie%20Podg%C3%B3rze.%20Prze%C5%BCyj%20prawdziwy%20smak%20Neapolu%20dzi%C4%99ki%20naszym%20r%C4%99cznie%20robionym%20pizzom%20przygotowywanym%20wed%C5%82ug%20tradycyjnych%20receptur%20i%20z%20wysokiej%20jako%C5%9Bci%20sk%C5%82adnik%C3%B3w.%20Odwied%C5%BA%20Pizzicletta%20ju%C5%BC%20dzi%C5%9B!/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2F731d6248-a2f2-4c89-abed-8f9a95cc84d9.jpg%3Ftoken%3DsPH3VQt-_QkVBgmm-TcHCovWmpAYuGJl7aAaLbTegLc%26height%3D624%26width%3D1200%26expires%3D33239784235/og.png"
        />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <NextUIProvider>
        <SWRProvider fallback={pageProps.fallback}>
          <GoogleAnalytics trackPageViews />
          <main className="w-full h-full bg-slate-300  select-none text-pizza-papaya">
            <CookieConsent
              buttonClasses="!bg-pizza-red !text-pizza-papaya !rounded-full"
              buttonText={t("cookieConsentButton")}
              containerClasses="shadow !justify-center"
              buttonWrapperClasses="flex-1 !justify-center"
              contentClasses="md:!flex-none"
            >
              {t("cookieConsentText")}
            </CookieConsent>

            <LanguageFlags />
            <BannerComponent />
            <Component {...pageProps} />
          </main>
        </SWRProvider>
      </NextUIProvider>
    </>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  