import { appConfig } from "./appConfig";

export const fetcher = <T>(resource: string, init?: any): Promise<T> => {
  const isServer = typeof window === "undefined";

  const baseURL = isServer ? appConfig.serverApiUrl : appConfig.clientApiUrl;

  const url = resource.startsWith("http") ? resource : `${baseURL}${resource}`;

  return fetch(url, {
    ...init,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${appConfig.apiBearerToken}`,
      ...init?.headers,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json() as Promise<T>;
  });
};
